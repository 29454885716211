<!--能源-设置--虚拟设备管理---->
<template>
  <div id="virtialEquipmentIndex" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <!-- 虚拟设备管理 -->
    <my-header-title>{{ $t("energy.enedata478") }}</my-header-title>
    <div class="header">
      <div class="header-left">
        
        <div class="header-condition">
            <!-- <span> 设备类型：</span> -->
            <span> {{$t('energy.enedata467')}}：</span>
            <a-select v-model="iconNo" style="width: 240px;">
              <a-select-option
                  v-for="(itme, index) in iconList"
                  :key="index"
                  :value="itme.no"
                >
                  {{ itme.text }}
                </a-select-option>
            </a-select>
        </div>
        <div class="header-condition">
            <!-- <span> 设备所属：</span> -->
            <span> {{$t('energy.enedata473')}}：</span>
            <a-cascader  style="width: 240px;"
                v-model="grs" 
                :field-names="{ label: 'text', value: 'no', children: 'children' }" :options="pointGrs" 
                change-on-select   />
              
        </div>
        <div class="header-condition">
          <span>{{$t('energy.enedata164')}}：</span>
          <a-input v-model="equipmentName"  :placeholder="getPlaceholder(2,'energy.enedata164')"  style="width: 160px"/>
        </div>
       
      </div>
      <div>
        <a-button type="primary" style="width: 120px"  @click="search()">{{$t('energy.enedata006')}} </a-button>
      </div>
    </div>
    <div class="main" ref="main">
         <!-- :scroll="tscroll" -->
         <a-table 
         :row-selection="rowSelection"
         :columns="columns" 
         :data-source="data"
         :row-key="record => record.id"  
         :pagination="pagination"  
         bordered size="middle" >
                <template slot="chaozuo" slot-scope="text, record ">
                    <div class="editable-row-operations">
                        <a-button type="primary" @click="() => editRecord(record)" style="margin-right:5px;">{{$t('energy.enedata189')}}</a-button>
                        <a-popconfirm :title="$t('energy.enedata475')" @confirm="() => deleteRecord(record)">
                            <a-button type="danger">{{$t('energy.enedata169')}}</a-button>
                        </a-popconfirm>
                    </div>
                </template>
        </a-table>
      <div class="footer">
          <div>
              <a-button type="primary" style="width: 120px" @click="batchDeleta" :disabled="selectedRowKeys.length==0">
                {{$t('energy.enedata169')}}
              </a-button>
            
          </div>
          <a-button type="primary" style="width: 120px" @click="addEquipment">
              {{$t('energy.enedata191')}}
          </a-button>
      </div>
    </div>
    <ene-virtial-equipment-dialog :id="equipmentId" :title="title" :iconList="siconList" :pointGrs="spointGrs"></ene-virtial-equipment-dialog>
  </div>
</template>



<script>
import { getVirtialEquipmentList ,getVirtialEquipment   ,deleteVirtialEquipment} from "../../../api/energy";
import EneVirtialEquipmentDialog from './eneVirtialEquipmentDialog.vue';
import headerTitle from "../../../components/Title/headerTitle";

export default {
  name: "virtialEquipmentIndex",
  data() {
    
    return {
      loading: false,
      data:[],
      equipmentName:'',
      parentId:'',
      grs:[''],
      iconNo:'',
      columns:[
         {title: 'NO'		 
          ,dataIndex: "NO",
            customRender: (text, record, index) =>
              `${
                (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                index +
                1
              }`,
            width: 60,},
         
          {title: this.$t('energy.enedata467')		 , dataIndex: 'iconText'		  		,width:200      },
          {title: this.$t('energy.setting.reportGr.kikiName')		 , dataIndex: 'name'		  		,width:200      },
          {title: this.$t('energy.enedata473')//'设备所属'		 
          ,dataIndex: "parentId",
            customRender: (text, record, index) =>
              `${
                this.getGrName(record.parentId)
              }`,
            },
        
          {
              title: this.$t('energy.enedata151'),
              dataIndex: "chaozuo",
              scopedSlots: { customRender: "chaozuo" },
              width: 200,
              fixed: "right",
          },
      ],
     
      pagination:{
          total: 0, //数据总数
          pageSize: 100, //每页中显示10条数据
          showTotal: (total) => {
            let msg = this.$t("energy.enedata195");
            msg = msg.replace('${total}',total);
            return msg;
              // `共 ${total} 条数据`
          }, //分页中显示总的数据
          showQuickJumper: true, //是否可以快速跳转至某页
          defaultCurrent: 1, //默认当前页面数
          hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
          onShowSizeChange: (current, pageSize) => {
            this.pagination.defaultCurrent = current;
            this.pagination.pageSize = pageSize;
            this.loadEquipmentList(1,current,pageSize);
          },
          // 改变每页数量时更新显示
          onChange: (current, size) => {
            this.pagination.defaultCurrent = current;
            this.pagination.pageSize = size;
            this.loadEquipmentList(1,current,size);
          },
      },
      
      selectedRowKeys:[],
      tscroll:{x:700,y:240},
      
      equipmentId:0,
      title:this.$t('energy.enedata474'),//'虚拟设备设置',
      iconList:[{no: "", text: "　"}],
      pointGrs:[{no: "", text: "　"}],
      siconList:[],
      spointGrs:[],
      dialogVisible:false,
    };
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onSelect: this.onSelectChange2,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        type:'checkbox',
      };
    },
  },
  activated() {
    this.loadEquipmentList(0,0,0);
  },
  mounted() { 
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  methods:{
    getGrName(pId){
      let id = pId+'';
      let names = [];
      let ex = 0;
      for (let i = 0; i < this.spointGrs.length; i++) {
        let pg = this.spointGrs[i];
        if(pg.no===id){
          names[0] = pg.text;
          ex = 1;
          break;
        }
        if(ex==0 && pg.children){
            for (let j = 0; j < pg.children.length; j++) {
              let sc = pg.children[j];
              if(sc.no===id){
                names[1] = sc.text;
                ex = 1;
                break;
              }
              if(ex==0 && sc.children){
                  for (let k = 0; k < sc.children.length; k++) {
                    let th = sc.children[k];
                    if(th.no===id){
                     names[2] = th.text;
                      ex = 1;
                      break;
                    }
                  }
                  if(ex == 1){
                     names[1] = sc.text;
                     break;
                  }
              }
            }

            if(ex == 1){
                names[0] = pg.text;
                break;
            }
        }
      }
      let name = '';
      if(names.length>0){
        // for (let index = names.length-1; index >= 0; index--) {
        //   name += names[index]+"/";
          
        // }
        name = names.join('/')
      }

      return name;
    },
    getPlaceholder(type,code){
      let str = '';
      let msg = this.$t('energy.enedata307');
      if(type===1){
        str = msg.replace("{text}",code);
      }else{
        str = msg.replace("{text}",this.$t(code));
      }
      return str;
    },
       // 表格高度计算
    tableSize() {
        setTimeout(() => {
            let height = this.$refs.main.clientHeight;
            let width = this.$refs.main.clientWidth;
            let tableHeight = height - 140;
            this.tscroll.y = tableHeight;
            this.tscroll.x = width;
            console.log(width);
            console.log(tableHeight);
        }, 0);
        
    },
    editRecord(record){
        this.dialogVisible = true;
        this.equipmentId = record.id;
    },
    deleteRecord(record){
        let idList = new Array();
        idList.push(record.id);
        this.deleteEquipment(idList);
    },
    batchDeleta(){
        let len = this.selectedRowKeys.length;
        if(len<1){
            this.$message.error(this.$t("energy.enedata476"));//'请先选择需要删除的虚拟点');
            return ;
        }
        let idList = this.selectedRowKeys;
        this.$confirm({
            title: this.$t('energy.enedata477'),
            centered: true,
            onOk: () => {
                this.deleteEquipment(idList);
            }
        });
    },
    deleteEquipment(idList){
        let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            idList:idList,
        };
       this.loading = true;
       try{
            console.log("DeleteEquipment Params",params);
            deleteVirtialEquipment(params)
            .then((res) => {
                console.log("LoadData Result",res.data);
                if(res.errorCode === '00' || res.errorCode === '05'){
                    this.selectedRowKeys = [];
                    this.$message.success(res.msg);
                    this.search();
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            })
            .catch((err) => {
                console.log('deleteVirtialEquipmentData',err);
                  this.loading = false;
            });
       }catch(e){
            console.log('ddddddddddddddddddddddddddd',e);
              this.loading = false;
       }
    },
    addEquipment(){
        this.dialogVisible = true;
        this.equipmentId = 0;
    },
  
    loadEquipmentList(action,pageNum,pageSize){
        if(action===1){
          if(this.grs.length>0){
            this.parentId = this.grs[this.grs.length-1];
          }
        }
        let params =  {
            action:action,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            name:this.equipmentName,
            parentId:this.parentId===''?0:this.parentId,
            iconNo:this.iconNo===''?0:this.iconNo,
            pageNum:pageNum,
            pageSize:pageSize,
        };
        console.log("InitPage Params",params);
        this.loading = true;
        getVirtialEquipmentList(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){
              if(action==0){
                  this.iconList = [{no: "", text: this.$t("energy.enedata472")},...res.data.iconList];
                  this.pointGrs = [{no: "", text: this.$t("energy.enedata472")},...res.data.pointGrs];
                  this.siconList = res.data.iconList;
                  this.spointGrs = res.data.pointGrs;
              }else if(action==1){
                  this.data = res.data.virtualEquipmentList;
                  this.pagination.total = res.data.total;
                  this.tableSize();
              }
                
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    search(){
        this.loadEquipmentList(1,1,this.pagination.pageSize);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
     onSelectChange2(record, selected, selectedRows, nativeEvent) {
      console.log("onSelectChange2>>: ",selected, selectedRows);
      this.selectedRows    = selectedRows;
      this.selectedRowKeys = [];
      this.selectedRows.forEach(element => {
          this.selectedRowKeys.push(element.id);
      });
    },
  },
  components: {
    'ene-virtial-equipment-dialog':EneVirtialEquipmentDialog,
    'my-header-title':headerTitle,
  },
};
</script>

<style scoped>

#virtialEquipmentIndex {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'

}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 10px 0;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 22px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  overflow: hidden;
}
.header-left {
  display: flex;
}
.header-condition {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 25px;
}
.main{
    width: 100%;
    height: calc(100% - 120px);
    box-shadow: 0px 3px 8px #e7e6e6;
}
.footer {
  display: flex;
  justify-content:space-between;
  height: 52px;
  padding: 10px 10px;
}

input {
  width: 14%;
}
button {
  width: 80px;
}
.footer button {
  margin-left: 15px;
}
</style>



