<template>
  <div id="eneVirtialEquipmentDetail" ref="eneVirtialEquipmentDetail">
    <a-modal v-model="visible" :title="title"  :width="900" :getContainer="() => this.$refs.eneVirtialEquipmentDetail" :footer="null">
        <div v-loading="cloading" :element-loading-text="$t('alarm.a0')">
        <a-form-model ref="ruleForm" 
        :model="form" 
        :rules="rules"
        :label-col="labelCol" 
        :wrapper-col="wrapperCol" layout="horizontal" >
            <a-form-model-item ref="name" prop="name">
                 <span slot="label">
                                {{$t('energy.enedata164')}}&nbsp;
                    <a-tooltip :title='disallowed'>
                    <a-icon type="question-circle-o" />
                    </a-tooltip>
                </span>
                <a-input 
                    v-model="form.name"
                    @blur="
                    () => {
                        $refs.name.onFieldBlur();
                    }
                    "
                    :placeholder="$t('energy.enedata470')"
                />
            </a-form-model-item>

            <a-form-model-item :label="$t('energy.enedata199')" ref="grs" prop="grs">
                <a-cascader 
                    v-model="form.grs" 
                    :field-names="{ label: 'text', value: 'no', children: 'children' }" :options="pointGrs" 
                    change-on-select  :placeholder="$t('energy.enedata228')" />
            </a-form-model-item>
        
            <a-form-model-item :label="$t('energy.enedata467')" ref="iconNo" prop="iconNo">
                <a-select v-model="form.iconNo" :placeholder="$t('energy.enedata263')" >
                    <a-select-option
                        v-for="(itme, index) in iconList"
                        :key="index"
                        :value="itme.no"
                        >
                        {{ itme.text }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item :label="$t('energy.enedata130')" ref="targetPoints" prop="targetPoints">
                <a-transfer
                    :data-source="mockData"
                    :list-style="{
                        width: '280px',
                        height: '300px',
                    }"
                    :rowKey="record => record.no"
                    :target-keys="form.targetPoints"
                    :render="renderItem"
                    @change="handleChange"
                />  
            </a-form-model-item>
             <a-form-model-item v-bind="tailFormItemLayout">
                <a-button type="primary" @click="onSubmit">
                    {{$t('energy.enedata173')}}
                </a-button>
                <a-button style="margin-left: 10px;" @click="resetForm" v-if="id==0">
                    {{$t('energy.enedata333')}}
                </a-button>
            </a-form-model-item>
            
        </a-form-model>
        </div>
    </a-modal>
  </div>
</template>
<script>
import {saveVirtialEquipment} from "../../../api/energy";
export default {
  name: 'EneVirtialEquipmentDetail',
  props: {
    title: {
      type: String,
      default: '虚拟点设置',
    },
    id: {
      type: Number,
      default:0,
    },
    iconList:{
        type:Array,
        default:[],
    },
    pointGrs:{
        type:Array,
        default:[],
    }
  },
  data() {
    let normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:",\/;']/im;
 
        if(regEn.test(value)) {
            callback(new Error(this.$t('energy.enedata260')));
        } else {
        callback();
      }
    };
    return {
        cloading:false,
        visible:false,

        form:{id:0,name:'',iconNo:undefined,title:'',description:'',grs:[],targetPoints:[]},
        srcForm:{id:0,name:'',iconNo:'',title:'',description:'',grs:[],targetPoints:[]},
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        tailFormItemLayout: {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 18,
                    offset: 6,
                },
            },
        },

        targetKeys:[],
        mockData:[],
        grs:[],

        rules: {
            name: [
                { required: true, message: this.$t('energy.enedata470'), trigger: 'blur' },
                { min: 1, max: 30, message: this.$t('energy.enedata471'), trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            iconNo: [{ required: true, message: this.$t('energy.enedata468'), trigger: 'change' }],
            targetPoints:[{
                type: 'array',
                required: true,
                message: this.$t('energy.enedata469'),//'请选择所属',
                trigger: 'change',
                 },
            ],
            grs:[{
                type: 'array',
                required: true,
                message: this.$t('energy.enedata228'),//'请选择所属',
                trigger: 'change',
                 },
            ],
        }, 
        disallowed:this.$t('energy.setting.reportGr.stop')+'[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】',
    };
  },
  mounted(){
   
  },
  watch:{
    "$parent.dialogVisible":{
      handler(val){
          if(val){              
              this.visible = val;
            //   this.form={id:0,name:'',iconNo:undefined,title:'',description:'',grs:[],targetPoints:[]};
              this.resetForm();
              this.load();
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          console.log()
          if(!val){
              this.$parent.dialogVisible = val;
              this.resetForm();
          }
      },
      deep:true,
      immediate:true,
    },
  },
  methods: {
    renderItem(item) {
        const customLabel = (
            <span class="custom-item">
             {item.text}
            </span>
        );

        return {
            label: customLabel, // for displayed item
            value: item.no, // for title and filter matching
        };
    },
    handleChange(targetKeys, direction, moveKeys) {
      console.log(targetKeys, direction, moveKeys);
      this.targetKeys = targetKeys;
      this.form.targetPoints = targetKeys;
    },
    onSubmit() {
        this.$refs.ruleForm.validate(valid => {
            if (valid) {
                    this.$confirm({
                    title: this.$t('energy.enedata264'),//'确认要保存所设置的虚拟点信息吗？',
                    centered: true,
                    onOk: () => {
                        this.sava();
                    },
                });
            } else {
                console.log('error submit!!');
                return false;
            }
        });
    },
    resetForm() {
        if(this.$refs.ruleForm){
            this.form = this.srcForm;
            this.$refs.ruleForm.resetFields(this.srcForm);
        }
    },
    load(){
        let param = {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            id:this.id,
        };
        this.savaVirtialEquipmentInfo(1,param);
    },
    sava(){
        // let gr1 = this.form.grs.length>0?this.form.grs[0]:0;
        // let gr2 = this.form.grs.length>1?this.form.grs[1]:0;
        // let gr3 = this.form.grs.length>2?this.form.grs[2]:0;
        let parentId = this.form.grs[this.form.grs.length-1];
        let pointList = [];
        this.form.targetPoints.forEach(ele => {
            let points = ele.split('@@');
            let point = {
                pointId:points[0],
                pointCategory:points[1],
                memo:'',
            };
            pointList.push(point);
        });
        let param = {
            action:2,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            id: this.form.id,
            name: this.form.name,
            parentId:parentId,
            iconNo: this.form.iconNo,
            title: '',
            description: '',
            iconOrder:null,
            pointList: pointList,
        };

        this.savaVirtialEquipmentInfo(2,param);
    },
    savaVirtialEquipmentInfo(action,param){
        
        console.log("SavaVirtialEquipment param",param);
        this.cloading = true;
        
        saveVirtialEquipment(param)
        .then((res) => {
            console.log("LoadData Result",res.data);
            if(res.errorCode === '00' || res.errorCode === '03'){
                if(action==0){
                }else if(action==1){
                    this.mockData = res.data.measurementPointList;
                    if(this.id>0){
                        this.form = {
                            id:res.data.virtualEquipment.id,
                            name:res.data.virtualEquipment.name,
                            iconNo:res.data.virtualEquipment.iconNo+"",
                            title:'',
                            description:'',
                            grs:res.data.virtualEquipment.grs,
                            targetPoints:res.data.virtualEquipment.targetPoints,
                        };
                        this.srcForm = this.form;
                    }
                }else if(action==2){
                    this.$message.success(res.msg);
                    this.visible = false;
                    this.$parent.search();
                }
            } else {
                this.$message.error(res.msg);
            }
            this.cloading = false;

        })
        .catch((err) => {
            this.cloading = false;
            console.log('saveVirtialEquipment',err);
        });
    },
  },
};
</script>
<style scoped>
    #eneVirtialEquipmentDetail{
        height: 100%;
        width:100%;
        position: relative;
    }
</style>